import styled, { css } from 'styled-components'

export const Container = styled.div`
  /* padding: 20px 100px; */
  background-color: #f4f4f4;
  /* padding-top: 1rem; */
  /* min-height: 80vh; */

  ${({ showSlider }) =>
    showSlider
      ? css`
          padding: 0px;
          @media only screen and (min-width: 1440px) {
            min-height: 88vh;
          }
        `
      : css`
          padding: 20px 100px;
          padding-top: 1rem;

          @media only screen and (max-width: 600px) {
            padding: 20px 35px;
          }
        `}

  ${({ isDescriptionScreen }) =>
    isDescriptionScreen &&
    css`
      @media only screen and (max-width: 1024px) {
        @media (orientation: landscape) {
          min-height: auto;
        }
      }
    `}
`

export const SlideShowWrapper = styled.div`
  background-color: white;
  position: absolute;
  width: 100vw;
  max-width: 1240px;
  top: 0;
  z-index: 9;
  bottom: 0;
  padding: 50px 100px;

  @media (orientation: landscape) {
    padding: 50px 63px;
    @media only screen and (min-width: 1440px) {
      max-width: 1313px;
    }
  }

  @media only screen and (max-width: 1024px) {
    @media (orientation: portrait) {
      /* height: 90vh; */
      padding: 0px;
    }
    @media (orientation: landscape) {
      padding: 0px;
    }
  }
`

export const GaleryWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
export const FooterWrapperEmpty = styled.div`
  @media only screen and (min-width: 1440px) {
    height: 97px;
  }
`

export const LeftGalery = styled.div`
  flex: 2;
  img {
    width: 100%;
  }
  height: 100%;

  @media only screen and (max-width: 600px) {
    // display: none;
  }
`

export const RightGalery = styled.div`
  flex: 1;
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 98%;
    margin: 4px 0;
  }
  @media only screen and (max-width: 730px) {
    // display: none;
    margin-left: 0px !important;
    margin-top: 1px;
  }
`

export const TextDescription = styled.div`
  font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: space-between;
  margin: 36px 0;
  width: 100%;
  min-height: 250px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    margin-top: 20px;
  }
`

export const TitleWrapper = styled.div`
  width: 48%;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
  }

  h3 {
    color: #00095b;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 43px;

    @media only screen and (max-width: 1024px) {
      font-size: 25px;
    }
  }
`

export const TextWrapper = styled.div`
  width: 50%;

  color: #00095b !important;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  p {
    color: #00095b;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;

    span {
      color: #00095b !important;
    }
  }

  ul,
  ol {
    padding-left: 15px;
    margin: 5px 0;
  }

  ol {
    padding-left: 25px;
    margin: 5px 0;
  }

  ul,
  ol > li {
    color: #00095b !important;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;

    span {
      color: #00095b !important;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }

  .disclaimer-text {
    margin-top: 30px;
    color: #00095b;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 24px;
  }
`

export const NavButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: FordAntennaCondRegular;
  letter-spacing: 1px;

  &:hover {
    span {
      color: #1700f4;
    }
    .fds-font--ford-icons__chevron-left-blue-with-hover:before {
      color: #1700f4;
    }
    .fds-font--ford-icons__chevron-right-blue-with-hover:before {
      color: #1700f4;
    }
  }
  span {
    color: #00095b;
    font-size: 18px;
  }

  .fds-icon {
    span {
      font-size: 14px;
      font-weight: bold;
    }
    .fds-font--ford-icons__chevron-left-blue-with-hover {
      margin-right: 10px;
    }

    .fds-font--ford-icons__chevron-right-blue-with-hover {
      margin-left: 10px;
    }
  }
`

export const NavButton = styled.div``

export const ButtonTextWrapper = styled.div`
  margin-bottom: 10px;
  label {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    display: inline-block;
    font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
    color: #4d4d4d;
  }
  .selectItemWrapper {
    width: 100%;
    /* max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; */

    @media (max-width: 746px) {
      width: 350px !important;
    }

    /* @media (min-width: 335px) and (max-width: 745px) {
      width: 350px;
    }

    @media only screen and (max-width: 280px) {
      width: 251px;
    } */
  }
`

export const WrapperHeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .select-wrapper {
    min-width: 350px;
  }

  .page-control {
    min-width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    @media (max-width: 1000px) {
      min-width: 100%;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    .select-wrapper {
      min-width: 100%;
    }
  }
`

export const ButtonSecondary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1700f4;
    /* text-decoration: underline; */
    cursor: pointer;
    .fds-font--ford-icons__chevron-left-blue-with-hover:before {
      color: #1700f4 !important;
    }
  }

  a {
    color: #00095b;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    font-family: 'Ford Antenna Cond';
    text-decoration: none;

    &:hover {
      color: #1700f4;
      /* text-decoration: underline; */
      cursor: pointer;
      .fds-font--ford-icons__chevron-left-blue-with-hover:before {
        color: #1700f4 !important;
      }
    }

    @media only screen and (min-width: 1024px) {
    }

    .fds-icon {
      margin-right: 0.5rem;
      place-self: center;
      font-size: 0.8rem;
    }

    .fds-font--ford-icons__chevron-left-blue-with-hover::before {
      color: #00095b;
      &:hover {
        color: #1700f4 !important;
      }
    }

    .fds-font--ford-icons__chevron-left-blue-with-hover {
      color: #00095b;
      font-weight: 600;
      &:hover {
        color: #1700f4;
        /* text-decoration: underline; */
      }
      &::before {
        &:hover {
          color: #1700f4;
        }
      }
    }
  }
`

export const BackButton = styled.div`
  max-width: 335px;
  padding: 5px 0px;
  margin: 10px auto;
  width: 100%;
  align-self: start;
  display: flex;

  @media only screen and (min-width: 636px) {
    max-width: 30px;
    margin: 10px 0px 25px;
  }

  @media only screen and (max-width: 1024px) {
    margin: 10px 0px 15px;
  }

  .text {
    font-size: 18px;
    line-height: 24px;
    font-family: FordAntennaCondRegular;
  }
`

export const BlueLabel = styled.div`
  display: none;
  width: 100%;
  background-color: #00095b;
  opacity: 0.9;
  padding: 22px 0 18px 0px;
  span {
    color: #ffffff;
    font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
    font-size: 32px;
    letter-spacing: 0;
    margin-left: 30px;
  }
`

export const ImageLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  height: ${props =>
    props.breakpoints && props.breakpoints.xs
      ? '189px'
      : props.breakpoints.md
      ? '352px'
      : props.breakpoints.mx
      ? '242px'
      : '462px'};
  transition: background-size 200ms linear;
  cursor: pointer;
  background-repeat: no-repeat;

  /* &:hover {
    background-size: ${props => (props.breakpoints && props.breakpoints.md ? 'cover' : '120%')};
    align-items: center;
  } */

  &:hover > div {
    display: block;
  }
`

export const ImageRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${({ url }) => url});
  background-position: ${props => (props.breakpoints && props.breakpoints.sm ? 'right' : 'left')};

  background-size: cover;

  width: 100%;
  background-repeat: no-repeat;
  height: ${props => (props.breakpoints && props.breakpoints.md ? '175px' : props.breakpoints.mx ? '120px' : '230px')};
  margin-right: ${props => (props.breakpoints && props.breakpoints.md ? '1px' : '0')};

  transition: background-size 200ms linear;
  cursor: pointer;

  /* &:hover {
    background-size: ${props => (props.breakpoints && props.breakpoints.md ? 'cover' : '120%')};
    align-items: center;
  }

  &:hover > div {
    display: block;
  }

  &:hover > span {
    justify-self: center;
  } */

  @media only screen and (max-width: 425px) {
    height: 100px;
  }
  @media only screen and (max-width: 730px) {
    // display: none;
    margin-bottom: 0px !important;
  }
`
