import React, { useEffect, useLayoutEffect, useState } from 'react'

import {
  Container,
  GaleryWrapper,
  LeftGalery,
  RightGalery,
  TextDescription,
  TitleWrapper,
  TextWrapper,
  ButtonSecondary,
  BackButton,
  ImageLeft,
  ImageRight,
  NavButton,
  SlideShowWrapper,
  ButtonTextWrapper,
  NavButtonWrapper,
  WrapperHeaderControls
} from './styles'

import SelectElm from '../../../../components/Select'

import Slider from '../../../../components/Slider'

import { sortByObjectValue } from '../../../../helpers'
import { navigate } from 'gatsby'
import slugify from 'slugify'
import styled, { css } from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import ReactHtmlParser from 'react-html-parser'
import { Disclaimer } from '../../../../components/Disclaimer'
import { ButtonPrimary } from '../../../../components/Buttons/style'
import { SynthesizeSpeech } from '../../../../services/text-to-speech'

const ImageShow = styled.div`
  ${({ isVideo }) =>
    !isVideo &&
    css`
      background: url(${({ url }) => `https://www.ford360guide.com${url}`});
    `}

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 600px;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    @media (orientation: portrait) {
      height: 433px;
    }
    @media (orientation: landscape) {
      height: 100vh;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @media only screen and (max-width: 635px) {
    height: 179px;
  }
`

const CloseWrapper = styled.div`
  /* margin: 10px; */
  margin: -15px 25px 34px 0px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  &:hover {
    color: #1700f4;
  }

  @media only screen and (max-width: 1024px) {
    margin: 25px 25px 25px 0px;
    color: #00095b;
    span {
      font-size: 24px !important;
    }

    @media (orientation: landscape) {
      margin: 0;
      span {
        z-index: 1;
        background-color: white;
        padding: 20px;
      }
    }
  }
`

const MainGallery = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const ImageMain = styled.div`
  display: flex;
  align-items: center;
  background: url(${({ url }) => url});
  background-position: center;
  background-size: ${props => (props.main ? '140%' : props.breakpoints && props.breakpoints.md ? 'contain' : 'contain')};
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;

  height: ${props => (props.breakpoints.sm ? '242px' : '462px')};
  transition: background-size 200ms linear;
  cursor: pointer;
  display: flex;
  justify-content: center;

  /* &:hover {
    background-size: ${props => (props.main ? '160%' : '100%')};
    background-size: ${props => (props.breakpoints && props.breakpoints.md ? 'contain' : '120%')};
  }

  &:hover > div {
    display: block;
  } */
`

const ImageMainDouble = styled.div`
  display: flex;
  align-items: center;
  background: url(${({ url }) => url});
  background-position: center;
  background-size: ${props => (props.breakpoints && props.breakpoints.l ? '200%' : props.breakpoints.md ? '100%' : '140%')};
  background-size: ${props => props.breakpoints && props.breakpoints.md && 'cover'};

  background-repeat: no-repeat;
  width: 100%;

  height: ${props => (props.breakpoints.sm ? '242px' : '462px')};
  transition: background-size 200ms linear;
  cursor: pointer;
  display: flex;
  justify-content: center;

  /* &:hover {
    // background-size: ${props => (props.breakpoints && !props.breakpoints.md && props.breakpoints.l ? '180%' : '160%')};
    background-size: ${props => (props.breakpoints && props.breakpoints.l ? '220%' : props.breakpoints.md ? '100%' : '160%')};
    background-size: ${props => props.breakpoints && props.breakpoints.md && 'cover'};

  }

  &:hover > div {
    display: block;
  } */
`

const MainHalf = styled.div`
  width: 50%;
  margin-right: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PlayVideoGalleryWrapper = styled.div`
  background-color: rgba(0, 9, 91, 0.7);
  border-radius: 100%;
  cursor: pointer;
  padding: ${props => (props.right ? '15px 11px' : '30px 22px')};

  span::before {
    content: '\\E900';
    color: white;
    margin-left: ${props => (props.right ? '10px' : '15px')};
  }
`
const PlayVideoWrapper = styled.div`
  width: 124px;
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 9, 91, 0.7);
  border-radius: 100%;
  cursor: pointer;

  span::before {
    content: '\\E900';
    color: white;
    margin-left: 15px;
  }
`

const TextToSpeechWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
`

const Description = ({
  feature,
  features,
  model,
  car,
  envs,
  clusters,
  showSlider,
  setShowSlider,
  location,
  featurePage,
  basePath
}) => {
  const [featuresPerCluster, setFeaturesPerCluster] = useState({})
  const [orderFeatures, setOrderFeatures] = useState([])
  const [selectedCluster, setSelectedCluster] = useState(feature[envs.FEATURE_CLUSTER].Cluster_Name)
  const [currentIndex, setCurrentIndex] = useState()
  const [lastIndex, setLastIndex] = useState()
  const [showVideo, setShowVideo] = useState([false, false, false])
  const [selectedSlide, setSelectedSlide] = useState(0)
  const [changeSlider, setChangeSlider] = useState(false)
  const [synthesizeSpeech, setSynthesizeSpeech] = useState()
  const [started, setStarted] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const { voice_type } = featurePage

  const _basePath = basePath ? `/${basePath}` : ''

  useEffect(() => {
    setSynthesizeSpeech(new SynthesizeSpeech({ voiceType: voice_type }))
  }, [])

  useEffect(() => {
    return () => {
      if (synthesizeSpeech) {
        synthesizeSpeech.cancel()
      }
    }
  }, [synthesizeSpeech])

  const playTextToSpeech = percentage => {
    setIsPlaying(true)
    if (!started) {
      setStarted(true)
      synthesizeSpeech.play(feature.Feature_Description, percentage)

      if (percentage === 50) {
        if (window.gtag && car) {
          window.gtag('event', 'voice-listen', {
            event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
            event_label: window.location.href
          })
        }
      } else {
        if (window.gtag && car) {
          window.gtag('event', 'voice-1.5x', {
            event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
            event_label: window.location.href
          })
        }
      }
    } else {
      if (window.gtag && car) {
        window.gtag('event', 'voice-continue', {
          event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
          event_label: window.location.href
        })
      }
      synthesizeSpeech.resume()
    }
  }

  const pauseTextToSpeech = () => {
    if (isPlaying) {
      setIsPlaying(false)
      if (synthesizeSpeech) {
        synthesizeSpeech.pause()
      }

      if (window.gtag && car) {
        window.gtag('event', 'voice-pause', {
          event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
          event_label: window.location.href
        })
      }
    }
  }

  useEffect(() => {
    const tempFeaturesPerCluster = {}

    clusters
      .sort((a, b) => {
        return sortByObjectValue(a, b, 'priority')
      })
      .map(cluster => {
        tempFeaturesPerCluster[cluster.Cluster_Name] = []
      })

    features.forEach(feature => {
      const clusterName = feature[envs.FEATURE_CLUSTER].Cluster_Name
      const existingArray =
        tempFeaturesPerCluster[clusterName] && tempFeaturesPerCluster[clusterName].length
          ? tempFeaturesPerCluster[clusterName]
          : []

      tempFeaturesPerCluster[clusterName] = [...existingArray, feature]
        .sort((a, b) => {
          var featureA = a.Feature_Name.toUpperCase()
          var featureB = b.Feature_Name.toUpperCase()
          if (featureA < featureB) return -1
          if (featureA > featureB) return 1

          return 0
        })
        .sort((a, b) => {
          return sortByObjectValue(a, b, 'priority')
        })
    })

    setOrderFeatures(
      Object.keys(tempFeaturesPerCluster)
        .map(key => tempFeaturesPerCluster[key])
        .flat()
    )

    setFeaturesPerCluster(tempFeaturesPerCluster)
  }, [features, envs])

  useEffect(() => {
    if (orderFeatures.length) {
      setCurrentIndex(orderFeatures.findIndex(f => f.Feature_Name === feature.Feature_Name))
      setLastIndex(orderFeatures.length - 1)
    }
  }, [orderFeatures])

  useEffect(() => {
    if (showSlider) {
      openVideo(selectedSlide)
    }
  }, [showSlider])

  useEffect(() => {
    setShowVideo([false, false, false])
  }, [currentIndex, changeSlider])

  const media1 = feature?.media_1?.Image_or_Video_Cover?.url
  const media2 = feature?.media_2?.Image_or_Video_Cover?.url
  const media3 = feature?.media_3?.Image_or_Video_Cover?.url

  const options = clusters
    .sort((a, b) => {
      return sortByObjectValue(a, b, 'priority')
    })
    .map(cluster => {
      return { value: cluster.Cluster_Name, label: cluster.Cluster_Name }
    })

  const goToOtherCharacteristic = clusterName => {
    const modelYearSlug = slugify(`${model.Year_Model} ${model.Full_Name.toLowerCase().replace(`${model.Year_Model}`, '')}`)

    const firstFeature = featuresPerCluster[clusterName][0]
    setSelectedCluster(clusterName)

    navigate(`${_basePath}/${modelYearSlug}/characteristics/${slugify(firstFeature.Feature_Name.toLowerCase())}`)
  }

  const goBackItem = () => {
    const nextIndex = currentIndex - 1

    if (nextIndex >= 0) {
      if (window.gtag && car) {
        window.gtag('event', 'feature-page-previous', {
          event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
          event_label: window.location.href
        })
      }

      const nextFeature = orderFeatures[nextIndex]
      const modelYearSlug = slugify(`${model.Year_Model} ${model.Full_Name.toLowerCase().replace(`${model.Year_Model}`, '')}`)

      let navigateLink = `${_basePath}/${modelYearSlug}/characteristics/${slugify(nextFeature.Feature_Name.toLowerCase())}`

      const urlParams = new URLSearchParams(location.search)
      const callback = urlParams.get('callback')
      const char = urlParams.get('char')

      if (char) {
        navigateLink += `?char=${char}&callback=${callback}`
      } else if (callback) {
        navigateLink += `?callback=${callback}`
      }

      navigate(navigate(navigateLink))
    }
  }

  const goForwardItem = () => {
    const nextIndex = currentIndex + 1

    if (nextIndex <= lastIndex) {
      if (window.gtag && car) {
        window.gtag('event', 'feature-page-next', {
          event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
          event_label: window.location.href
        })
      }

      const nextFeature = orderFeatures[nextIndex]

      const modelYearSlug = slugify(`${model.Year_Model} ${model.Full_Name.toLowerCase().replace(`${model.Year_Model}`, '')}`)

      let navigateLink = `${_basePath}/${modelYearSlug}/characteristics/${slugify(nextFeature.Feature_Name.toLowerCase())}`

      const urlParams = new URLSearchParams(location.search)
      const callback = urlParams.get('callback')
      const char = urlParams.get('char')

      if (char) {
        navigateLink += `?char=${char}&callback=${callback}`
      } else if (callback) {
        navigateLink += `?callback=${callback}`
      }

      synthesizeSpeech.pause()

      navigate(navigate(navigateLink))
    }
  }

  const goBackToChar = () => {
    const modelYearSlug = slugify(`${model.Year_Model} ${model.Full_Name.toLowerCase().replace(`${model.Year_Model}`, '')}`)
    const urlParams = new URLSearchParams(location.search)
    const callback = urlParams.get('callback')
    const char = urlParams.get('char')

    if (window.gtag && car) {
      window.gtag('event', 'feature-page-back', {
        event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
        event_label: window.location.href
      })
    }

    if (char) {
      navigate(callback + `?char=${char}`)
    } else if (callback) {
      navigate(callback)
    } else {
      navigate(`/${modelYearSlug}`)
    }
  }

  const openSlider = slideNumber => {
    pauseTextToSpeech()
    setSelectedSlide(slideNumber)
    setShowSlider(true)
  }

  const openVideo = current => {
    setShowVideo(prevState => prevState.map((item, idx) => (idx === current ? true : false)))
    setSelectedSlide(current)
  }

  const breakpoints = useBreakpoint()

  const GalleryPictures = () => {
    if (media1 && media2 && media3) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: breakpoints.st && 'column'
          }}
        >
          <LeftGalery>
            <ImageLeft breakpoints={breakpoints} onClick={() => openSlider(0)} url={`https://www.ford360guide.com${media1}`}>
              {feature.media_1.Media_Type === 'Video' && (
                <PlayVideoGalleryWrapper>
                  <span className="fds-icon fds-icon--63"></span>
                </PlayVideoGalleryWrapper>
              )}
            </ImageLeft>
          </LeftGalery>
          <RightGalery style={{ flexDirection: breakpoints.st ? 'row' : 'column' }}>
            <ImageRight
              breakpoints={breakpoints}
              onClick={() => openSlider(1)}
              style={{ marginBottom: '2px' }}
              url={`https://www.ford360guide.com${media2}`}
            >
              {feature.media_2.Media_Type === 'Video' && (
                <PlayVideoGalleryWrapper right={true} breakpoints={breakpoints}>
                  <span className="fds-icon fds-icon--24"></span>
                </PlayVideoGalleryWrapper>
              )}

              {/* <BlueLabel>
                <span> Sync 3</span>
              </BlueLabel> */}
            </ImageRight>
            <ImageRight
              breakpoints={breakpoints}
              onClick={() => openSlider(2)}
              url={`https://www.ford360guide.com${media3}`}
              style={{ marginRight: breakpoints.st && 0 }}
            >
              {feature.media_3.Media_Type === 'Video' && (
                <PlayVideoGalleryWrapper right={true} breakpoints={breakpoints}>
                  <span className="fds-icon fds-icon--24"></span>
                </PlayVideoGalleryWrapper>
              )}
              {/* <BlueLabel>
                <span> Sync 3</span>
              </BlueLabel> */}
            </ImageRight>
          </RightGalery>
        </div>
      )
    } else if (media1 && media2 && !media3) {
      return (
        <>
          <MainHalf>
            <ImageMainDouble
              main={true}
              breakpoints={breakpoints}
              onClick={() => openSlider(0)}
              url={`https://www.ford360guide.com${media1}`}
            >
              {feature.media_1.Media_Type === 'Video' && (
                <PlayVideoGalleryWrapper breakpoints={breakpoints}>
                  <span className="fds-icon fds-icon--24"></span>
                </PlayVideoGalleryWrapper>
              )}

              {/* <BlueLabel>
                <span> Sync 3</span>
              </BlueLabel> */}
            </ImageMainDouble>
          </MainHalf>
          <MainHalf>
            <ImageMainDouble
              main={true}
              breakpoints={breakpoints}
              onClick={() => openSlider(1)}
              url={`https://www.ford360guide.com${media2}`}
            >
              {feature.media_2.Media_Type === 'Video' && (
                <PlayVideoGalleryWrapper breakpoints={breakpoints}>
                  <span className="fds-icon fds-icon--24"></span>
                </PlayVideoGalleryWrapper>
              )}
              <div style={{ height: '75%' }}></div>
              {/* 
              <BlueLabel>
                <span> Sync 3</span>
              </BlueLabel> */}
            </ImageMainDouble>
          </MainHalf>
        </>
      )
    } else if (media1 && !media2 && !media3) {
      return (
        <>
          <MainGallery>
            <ImageMain breakpoints={breakpoints} onClick={() => openSlider(0)} url={`https://www.ford360guide.com${media1}`}>
              {feature.media_1.Media_Type === 'Video' && (
                <PlayVideoGalleryWrapper>
                  <span className="fds-icon fds-icon--63"></span>
                </PlayVideoGalleryWrapper>
              )}
              <div style={{ height: '75%' }}></div>

              {/* <BlueLabel>
                <span> Sync 3</span>
              </BlueLabel> */}
            </ImageMain>
          </MainGallery>
        </>
      )
    } else {
      return <></>
    }
  }

  const ItemChar = () => {
    return (
      <>
        <ButtonTextWrapper>
          <BackButton>
            <ButtonSecondary>
              <a onClick={() => goBackToChar()}>
                <span className="fds-icon fds-color__text--blue">
                  <span className="fds-font--ford-icons__chevron-left-blue-with-hover"></span>
                </span>
                <span className="text">{featurePage.navigate_back_button_text}</span>
              </a>
            </ButtonSecondary>
          </BackButton>

          <WrapperHeaderControls>
            <div className="select-wrapper">
              <SelectElm
                options={options}
                showNoFeeback={true}
                value={{
                  value: feature[envs.FEATURE_CLUSTER].Cluster_Name,
                  label: feature[envs.FEATURE_CLUSTER].Cluster_Name
                }}
                onChange={option => goToOtherCharacteristic(option.value)}
                placeholder={''}
                label={featurePage.select_cluster_label}
                onClick={() => {
                  if (window.gtag && car) {
                    window.gtag('event', 'feature-highlight-dropdown', {
                      event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                      event_label: window.location.href
                    })
                  }
                }}
              />
            </div>
            <div className="page-control">
              {currentIndex !== 0 ? (
                <NavButtonWrapper onClick={() => goBackItem()}>
                  <span className="fds-icon">
                    <span className="fds-font--ford-icons__chevron-left-blue-with-hover"></span>
                  </span>
                  <NavButton>
                    <span>{featurePage.next_feature_button_text}</span>
                  </NavButton>
                </NavButtonWrapper>
              ) : (
                <div></div>
              )}
              {currentIndex !== lastIndex ? (
                <NavButtonWrapper onClick={() => goForwardItem()}>
                  <NavButton>
                    <span>{featurePage.back_feature_button_text}</span>
                  </NavButton>
                  <span className="fds-icon">
                    <span className="fds-font--ford-icons__chevron-right-blue-with-hover"></span>
                  </span>
                </NavButtonWrapper>
              ) : (
                <div></div>
              )}
            </div>
          </WrapperHeaderControls>
        </ButtonTextWrapper>
        {breakpoints.sm && (
          <TitleWrapper>
            <h3>{feature.Feature_Name}</h3>
          </TitleWrapper>
        )}
        <GaleryWrapper>
          <GalleryPictures />
        </GaleryWrapper>

        <TextDescription>
          {!breakpoints.sm && (
            <TitleWrapper>
              <h3>{feature.Feature_Name}</h3>
            </TitleWrapper>
          )}
          <TextWrapper>
            <TextToSpeechWrapper>
              {!isPlaying && !started && (
                <ButtonPrimary
                  onClick={() => playTextToSpeech(0)}
                  style={{ justifyContent: 'left', marginTop: 0, margin: 0, marginRight: '10px' }}
                >
                  <a>{featurePage.text_to_speech_listen_to_text_button}</a>
                </ButtonPrimary>
              )}
              {!isPlaying && !started && (
                <ButtonPrimary
                  onClick={() => playTextToSpeech(50)}
                  style={{ justifyContent: 'left', marginTop: 0, margin: 0, marginRight: '10px' }}
                >
                  <a>1.5x</a>
                </ButtonPrimary>
              )}
              {isPlaying && started && (
                <ButtonPrimary onClick={pauseTextToSpeech} style={{ justifyContent: 'left', margin: 0, marginRight: '10px' }}>
                  <a>{featurePage.text_to_speech_pause_text_button}</a>
                </ButtonPrimary>
              )}
              {!isPlaying && started && (
                <ButtonPrimary onClick={playTextToSpeech} style={{ justifyContent: 'left', margin: 0, marginRight: '10px' }}>
                  <a>{featurePage.text_to_speech_continue_text_button}</a>
                </ButtonPrimary>
              )}
            </TextToSpeechWrapper>

            {ReactHtmlParser(feature.Feature_Description)}
          </TextWrapper>
        </TextDescription>
        <div
          style={{
            marginLeft: !breakpoints.sm && '50%',
            width: !breakpoints.sm && '50%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 30
          }}
        >
          {currentIndex !== 0 ? (
            <NavButtonWrapper onClick={() => goBackItem()}>
              <span className="fds-icon">
                <span className="fds-font--ford-icons__chevron-left-blue-with-hover"></span>
              </span>
              <NavButton>
                <span>{featurePage.next_feature_button_text}</span>
              </NavButton>
            </NavButtonWrapper>
          ) : (
            <div></div>
          )}
          {currentIndex !== lastIndex ? (
            <NavButtonWrapper onClick={() => goForwardItem()}>
              <NavButton>
                <span>{featurePage.back_feature_button_text}</span>
              </NavButton>
              <span className="fds-icon">
                <span className="fds-font--ford-icons__chevron-right-blue-with-hover"></span>
              </span>
            </NavButtonWrapper>
          ) : (
            <div></div>
          )}
        </div>
        <Disclaimer disclaimer_text={feature.Disclaimer_text} />
      </>
    )
  }

  const SlideShow = ({ selectedSlide }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(selectedSlide)

    useLayoutEffect(() => {
      if (window) {
        const iframe = document.getElementById('video-player')

        if (!iframe) return

        const player = new Vimeo.Player(iframe)
        let completed25 = false
        let completed50 = false
        let completed75 = false
        let completed100 = false

        player.on('progress', function(progress) {
          if (progress.percent >= 0.25 && !completed25) {
            if (window.gtag && car) {
              window.gtag('event', 'video-25%', {
                event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                event_label: window.location.href
              })
            }
            completed25 = true
          }
          if (progress.percent >= 0.5 && !completed50) {
            if (window.gtag && car) {
              window.gtag('event', 'video-50%', {
                event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                event_label: window.location.href
              })
            }

            completed50 = true
          }

          if (progress.percent >= 0.75 && !completed75) {
            if (window.gtag && car) {
              window.gtag('event', 'video-75%', {
                event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                event_label: window.location.href
              })
            }

            completed75 = true
          }

          if (progress.percent >= 1 && !completed100) {
            if (window.gtag && car) {
              window.gtag('event', 'video-100%', {
                event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                event_label: window.location.href
              })
            }

            completed100 = true
          }
        })
      }
    }, [])

    return (
      <SlideShowWrapper>
        <CloseWrapper>
          <span
            onClick={() => {
              if (window.gtag && car) {
                window.gtag('event', 'feature-page-picture-close', {
                  event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                  event_label: window.location.href
                })
              }
              setShowSlider(false)
            }}
            className="fds-icon fds-font--ford-icons__clear"
          ></span>
        </CloseWrapper>

        <Slider
          changeSlider
          setChangeSlider={setChangeSlider}
          isDescriptionScreen
          slides={1}
          label={feature.Feature_Name}
          noSlither={true}
          selectedSlide={selectedSlide}
          activeItemIndex={activeItemIndex}
          setActiveItemIndex={setActiveItemIndex}
        >
          {[1, 2, 3].map(current => {
            const currentMedia = feature[`media_${current}`]
            return (
              currentMedia &&
              (currentMedia.Media_Type === 'Video' ? (
                <ImageShow isVideo>
                  <iframe
                    src={`${currentMedia.Vimeo_URL}?autoplay=1`}
                    width="80%"
                    height="100%"
                    frameborder="0"
                    webkitAllowFullScreen
                    mozAllowFullScreen
                    allowFullScreen
                    id="video-player"
                    onLoad={() => {
                      if (window.gtag && car) {
                        window.gtag('event', 'video-play', {
                          event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                          event_label: window.location.href
                        })
                      }
                    }}
                    allow={`${activeItemIndex === current - 1 ? 'autoplay; ' : ''}fullscreen`}
                  ></iframe>

                  <script src="https://player.vimeo.com/api/player.js"></script>
                </ImageShow>
              ) : (
                <ImageShow
                  url={currentMedia.Image_or_Video_Cover.url}
                  isVideo={currentMedia.Media_Type === 'Video'}
                  videoUrl={currentMedia.Vimeo_URL}
                  // onClick={() => openVideo(current)}
                ></ImageShow>
              ))
            )
          })}
        </Slider>
      </SlideShowWrapper>
    )
  }

  return (
    <Container isDescriptionScreen showSlider={showSlider}>
      {showSlider ? <SlideShow selectedSlide={selectedSlide} /> : <ItemChar />}
    </Container>
  )
}

export default Description
