import * as sdk from 'microsoft-cognitiveservices-speech-sdk'
import { SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk'

const voiceTypeTo = {
  FranciscaNeuralBrazil: 'pt-BR-FranciscaNeural',
  AntonioNeuralBrazil: 'pt-BR-AntonioNeural',
  ElenaNeuralArgentina: 'es-AR-ElenaNeural',
  TomasNeuralArgentina: 'es-AR-TomasNeural',
  CatalinaNeuralChile: 'es-CL-CatalinaNeural',
  LorenzoNeuralChile: 'es-CL-LorenzoNeural',
  SalomeNeuralColombia: 'es-CO-SalomeNeural',
  GonzaloNeuralColombia: 'es-CO-GonzaloNeural'
}

export class SynthesizeSpeech {
  constructor({ voiceType }) {
    this.speechConfig = sdk.SpeechConfig.fromSubscription('2b05d37abb5a486cab3ca1329931bbf9', 'brazilsouth')
    this.player = new sdk.SpeakerAudioDestination()
    this.audioConfig = sdk.AudioConfig.fromSpeakerOutput(this.player)
    this.synthesizer = new SpeechSynthesizer(this.speechConfig, this.audioConfig)
    this.voiceType = voiceTypeTo[voiceType]
  }

  play(textToSpeech, speed = 0) {
    const cleanText = textToSpeech
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/\&nbsp;/g, ' ')
      .replace('.', '. ')
    const header = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US">`
    const voice = ` <voice name="${this.voiceType}">`
    const prosody = `<prosody rate="${speed}%" pitch="0%">`
    const end = `</prosody></voice></speak>`

    const ssml = header + voice + prosody + cleanText + end

    this.synthesizer.speakSsmlAsync(
      ssml,
      result => {
        if (result) {
          this.synthesizer.close()
          return result.audioData
        }
      },
      error => {
        console.log(error)
        this.synthesizer.close()
      }
    )
  }

  pause() {
    this.player.pause()
  }

  resume() {
    this.player.resume()
  }

  cancel() {
    if (this.player) {
      this.player.pause()
    }

    this.player = null
  }
}
