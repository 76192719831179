import React from 'react'
import styled from 'styled-components'

export const DisclaimerWrapper = styled.div`
  display: ${({ isShowing }) => (isShowing ? 'block' : 'none')};

  width: 100%;

  .disclaimer-text {
    margin-top: 10px;
    font-style: italic;
    color: #00095b;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 24px;
  }
`

export const Disclaimer = ({ disclaimer_text }) => {
  return (
    <DisclaimerWrapper isShowing={!!disclaimer_text}>
      <p className="disclaimer-text">
        {disclaimer_text &&
          disclaimer_text.split('\n').map(t => {
            return (
              <>
                {t.split(' ').map(c => (
                  <>
                    <span>{c.length ? c : <>&nbsp;</>} </span>
                  </>
                ))}
                <br />
              </>
            )
          })}
      </p>
    </DisclaimerWrapper>
  )
}
