import React, { useEffect, useState } from 'react'
import { Template } from '../../../components/template'

import Header from '../../../components/Header'
import Footer from '../../../components/Footer'

import Description from './Description'
import { ToTopButton } from '../../../components/Buttons/ToTop'

const ItemCharacteristic = ({ pageContext, location }) => {
  const {
    metatag_title,
    metatag_description,
    metatag_language,
    Privacy_policy_url,
    has_cookie_consent,
    go_to_top_button_text,
    privacy_policy_text,
    privacy_policy_subtext,
    privacy_policy_accept_button_text
  } = pageContext.website.website_info
  const feature_name = pageContext.feature.Feature_Name
  const basePath = pageContext.basePath

  const [showSlider, setShowSlider] = React.useState(false)

  return (
    <Template
      title={metatag_title}
      description={metatag_description}
      language={metatag_language}
      subtitle={feature_name}
      has_cookie_consent={has_cookie_consent}
      site={pageContext.envs.SITE}
      privacy_policy_text={privacy_policy_text}
      privacy_policy_subtext={privacy_policy_subtext}
      privacy_policy_accept_button_text={privacy_policy_accept_button_text}
    >
      {!showSlider && (
        <Header
          model={pageContext.model}
          header={pageContext.website.header}
          basePath={pageContext.basePath}
          isNotHome
          car={pageContext.car}
          modelPage={pageContext.modelPage}
          envs={pageContext.envs}
        />
      )}
      {!showSlider && <div style={{ height: 80 }}></div>}
      <Description
        location={location}
        clusters={pageContext.clusters}
        feature={pageContext.feature}
        features={pageContext.features}
        envs={pageContext.envs}
        car={pageContext.car}
        model={pageContext.model}
        showSlider={showSlider}
        setShowSlider={setShowSlider}
        featurePage={pageContext.website.feature_page}
        basePath={basePath}
      />

      {!showSlider && (
        <Footer privacyPolicyUrl={Privacy_policy_url} footer={pageContext.website.footer} header={pageContext.website.header} />
      )}
      {!showSlider && <ToTopButton buttonText={go_to_top_button_text} />}
    </Template>
  )
}

export default ItemCharacteristic
